/*
    Established connection to API
*/

import axios from "axios";
import { SERVER_HOST } from "../../spectory-config";
import error_codes from "../error_codes.json";


export default class Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor(endpoint) {

        this.endpoint = endpoint;

    }


    /**
     * Fires & Handles GET Request
     * 
     * @param {String} request 
     * 
     * @returns {*}
     */
    get = async (request = '') => {

        let res = await axios.get(SERVER_HOST + this.endpoint + request);

        if (res.status !== 200) {
            return this.handle_error(res.statusText);
        } else {
            res.success = true;
        }

        return res;

    }

    /**
     * Fires & Handles POST Request
     * 
     * @param {String} request 
     * @param {Object} data 
     * 
     * @returns {*} 
     */
    post = async (request = '', data = {}) => {

        let res = await axios.post(SERVER_HOST + this.endpoint + request, data);

        if (res.status !== 200 || res.data.error !== undefined) {
            return this.handle_error(res.data.code);
        } else {
            res.success = true;
        }

        return res;

    }

    /**
     * Returns Error message
     * 
     * @param {String} error 
     */
    handle_error = (error) => {


        if (error_codes[error] === undefined) return "Ein Fehler ist aufgetreten, bitte versuche es erneut";

        return error_codes[error];

    }


}