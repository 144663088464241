import React from 'react';

import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import NotFound from './screens/NotFound';
import Password from './screens/Password';
import { BASENAME } from './spectory-config';



export default class App extends React.Component {

    constructor() {
        super();

        this.state = {
        }

    }

    componentDidMount() {

    }

    render() {


        return (
            <Router basename={BASENAME}>
                <Switch>

                    <Route exact path='/:token' render={(props) => {
                        return (<Password {...props} />)
                    }} />

                    <Route exact path='/' render={(props) => {
                        return (<NotFound {...props} />)
                    }} />

                </Switch>
            </Router >
        );

    }
}
