import React from 'react';


export default class NotFound extends React.Component {

    render() {
        return (
            <h1>404 - Not Found</h1>
        )
    }
}