/*
    Established connection to API
*/

import Communicator from ".";

export default class PasswordUnit extends Communicator {

    /**
     * 
     * @param {String} endpoint
     */
    constructor() {

        super('');

    }

    get = async (token) => {

        return await this.post('password_link_get', {
            token: token
        });

    }

}