import React from 'react';
import Loading from '../helper/screens/Loading';

import PasswordUnit from '../communication/http/passwords';
import NotFound from './NotFound';


export default class Password extends React.Component {

    constructor() {

        super();

        this.state = {
            password: null,
            user: null,
            comment: null,
            is404: null
        }

        this.passwordUnit = new PasswordUnit();

    }

    componentDidMount() {
        console.log(this.props.match.params.token)

        this.passwordUnit.get(this.props.match.params.token).then(res => {

            console.log(res)

            if (res.data !== undefined) {

                if (res.data.user !== undefined) {
                    this.setState({
                        user: res.data.user
                    })
                }
                if (res.data.password !== undefined) {

                    this.setState({
                        password: res.data.password
                    })

                }
                if (res.data.comment !== undefined) {

                    this.setState({
                        comment: res.data.comment
                    })

                }

                this.setState({
                    is404: false
                })

            } else {

                this.setState({
                    is404: true
                })

            }

        })

    }


    render() {

        if (this.state.is404 === true) {
            return <NotFound />
        } else if (this.state.is404 === false) {

            return (
                <>

                    <div className="wrapper">
                        {this.state.user !== null ?
                            <div className="container">
                                <div className="label">Username</div>
                                <div className="value">{this.state.user}</div>
                            </div>
                            : null
                        }
                        {this.state.password !== null ?
                            <div className="container">
                                <div className="label">Passwort</div>
                                <div className="value">{this.state.password}</div>
                            </div>
                            : null
                        }
                        {this.state.comment !== null ?
                            <div className="container">
                                <div className="label">Kommentar</div>
                                <div className="value">{this.state.comment}</div>
                            </div>
                            : null
                        }

                    </div>

                    <Loading visible={this.state.loading} />
                </>
            );

        } else {
            return null
        }
    }

}